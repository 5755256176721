<template>
  <router-view />
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="less">
* {
	margin: 0px;
}
html, body {
	background: #f7f7f7;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  text-align: center;
  color: #262626;
}
.flex {
	display: flex;
	align-items: center;
}
.required {
	color: red;
}
.el-checkbox-group {
	width: 90%;
	text-align: left;
}
</style>
