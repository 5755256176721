import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";

const routes=[
	{
		path: '/',
		name: 'Home',
		component: () => import('@/pages/index/index.vue')
	},
	{
		path: '/form',
		name: 'Form',
		component: () => import('@/pages/form/index.vue')
	}
];

const router=createRouter({
    history:createWebHistory(),
    routes
})

export default router;
 